<template>
  <div class="logon">
    <BgTop></BgTop>
    <div class="logon-main">
      <div class="logon-title">注册</div>

      <div class="input-box">
        <input placeholder="请输入账号" />
      </div>
      <div class="input-box input-box3">
        <input placeholder="输入验证码" />
        <div class="code-box">获取验证码</div>
      </div>
      <div class="input-box input-box2">
        <input placeholder="请输入密码" :type="type" />
        <img
          class="eyes"
          @click="type = 'text'"
          v-if="type === 'password'"
          src="@/assets/image/close-eyes.png"
        />
        <img
          class="eyes"
          @click="type = 'password'"
          v-if="type === 'text'"
          src="@/assets/image/open-eyes.png"
        />
      </div>

      <div class="input-box input-box2">
        <input placeholder="请输入确认密码" :type="type2" />
        <img
          class="eyes"
          @click="type2 = 'text'"
          v-if="type2 === 'password'"
          src="@/assets/image/close-eyes.png"
        />
        <img
          class="eyes"
          @click="type2 = 'password'"
          v-if="type2 === 'text'"
          src="@/assets/image/open-eyes.png"
        />
      </div>
      <button class="btn">注册</button>

      <div class="title-box">
        <span @click="goUrl">账号登录</span>
      </div>

      <div class="radio-box">
        <van-Checkbox
          checked-color="#FFA200"
          v-model="checked"
          shape="square"
        ></van-Checkbox>
        我已阅读并同意《速调吧》
        <span>用户协议</span>和
        <span>隐私政策</span>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      type: "password",
      type2: "password",
      checked: false
    };
  },

  methods: {
    goUrl() {
      this.$router.push("/mobile/logon");
    }
  }
};
</script>
<style lang="scss" scoped>
.logon {
  min-height: 100vh;
  width: 100%;
  position: relative;
  background-color: #fff;

  .logon-main {
    min-height: 100vh;
    width: 100%;
    position: relative;
    z-index: 2;
    padding: 0 38px;
    box-sizing: border-box;

    .logon-title {
      padding-top: 99px;
      text-align: center;
      color: #232323;
      font-size: 26px;
      margin-bottom: 40px;
    }

    .input-box {
      position: relative;
      width: 100%;
      margin-bottom: 20px;

      input {
        outline: none;
        border: none;
        width: 100%;
        height: 46px;
        background: rgba(255, 162, 0, 0.1);
        border-radius: 23px;
        color: #ffa200;
        padding: 0 23px;
        box-sizing: border-box;
        font-size: 14px;
      }

      input::placeholder {
        color: #cbcbcb;
      }

      .eyes {
        position: absolute;
        width: 24px;
        height: 24px;
        top: 50%;
        transform: translateY(-50%);
        right: 23px;
      }
    }

    .input-box2 {
      input {
        padding-right: 70px;
      }
    }

    .input-box3 {
      input {
        padding-right: 116px;
      }

      .code-box {
        position: absolute;
        width: 70px;
        text-align: center;
        color: #ffa200;
        font-size: 14px;
        top: 50%;
        transform: translateY(-50%);
        right: 23px;
      }
    }

    .btn {
      border: none;
      padding: 0;
      width: 100%;
      height: 46px;
      background: linear-gradient(138deg, #ffa200 0%, #ff6017 100%);
      border-radius: 23px;
      color: #ffffff;
      font-size: 18px;
      margin-top: 15px;
    }

    .title-box {
      display: flex;
      justify-content: center;
      color: #999999;
      font-size: 14px;
      margin-top: 25px;
    }

    .radio-box {
      width: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      color: #232323;
      font-size: 12px;
      margin-top: 20px;
      margin-bottom: 20px;
      flex-wrap: wrap;

      span {
        color: #ffa200;
      }

      .van-checkbox {
        margin-right: 7px;
      }
    }
  }
}
</style>
